.main {
  position: relative;
}

.image-container {
  position: relative;
  top: 80px;
  right: 0;
  min-height: 480px;
  width: 100%;
  background-image: url('./wiley_beach.jpg');
  background-size: cover;
  background-position: right top;
  background-repeat: no-repeat;
}

/* Linear gradient overlay */
.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 880px;
  width: 100%;
  background: linear-gradient(270deg, rgba(27, 45, 56, 0) 0%, rgba(50, 81, 101, 0.9) 100%);
  z-index: 0;
}

.text-overlay {
  position: absolute;
  z-index: 1;
  margin-top: 80px;
  color: white;
  font-family: 'Roboto Serif', serif;
  font-size: 15px;
  line-height: 30px;
  text-align: left;
  top: 90px;
  left: 15%;
  width: 800px;
}

.hero-text {
  font-family: 'Roboto Serif';
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  cursor: default;
}

.hero-text a {
  text-decoration: underline;
  cursor: pointer;
  color: white;
}

/* Flexbox Container for Leadership and Details Section */
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 130px;
  padding-top: 40px;
  margin-top: 80px;
  flex-direction: row; /* Default: side-by-side */
}

.leadership-section, .details-section {
  flex: 1;
}

.leadership-section {
  font-size: 17px;
  font-weight: 100;
  line-height: 37px;
  margin-left: 15%;
}

.leadership-section h1 {
  font-size: 17px;
  font-weight: 700;
  text-align: left;
  margin: 0px;
}

/* Responsive Styles */
@media (max-width: 1280px) {
  .text-overlay {
    width: 60%;
    left: 10%;
  }
  
  .leadership-section {
    margin-left: 10%;
  }

}

@media (max-width: 1024px) {
  .text-overlay {
    width: 70%;
    left: 5%;
  }

  .leadership-section {
    margin-left: 5%;
  }

}

@media (max-width: 768px) {
  .text-overlay {
    width: 75%;
    left: 2%;
  }

  .leadership-section {
    margin-left: 2%;
  }

  .hero-text {
    font-size: 20px;
    line-height: 40px;
  }
}

@media (max-width: 580px) {
  .text-overlay {
    width: 90%;
    left: 2%;
  }

  .leadership-section {
    margin-left: 2%;
  }

  .hero-text {
    font-size: 18px;
    line-height: 35px;
  }
}


.details-section {
  padding-right: 15%;
}

.details-section h1 {
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  padding-top: 30px;
}

.details-section .first-headline {
  padding-top: 0px;
}

.details-section p {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;  
}

.details-section button {
  background: none;
    border: none;
    color: #6fc068;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack sections on smaller screens */
  }

  .details-section {
    width: 96%;
    padding: 0px 2%;
  }
}



/* TOOL TIP */
.tooltip-container {
  position: absolute;
  top: 0px; /* Adjust top distance if needed */
  right: 0; /* Align to the right */
  display: inline-block;
  cursor: default;
  width: 25%;
  height: 480px;
  text-align: right;
  z-index: 5;
  /* background-color: pink; */
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 8px;

  /* Position the tooltip */
  position: absolute;
  bottom: 8%; /* Show above the element */
  right: 11%;
  transform: translateX(0%);
  margin-top: 10px;

  /* Fade-in effect */
  opacity: 0;
  transition: opacity 3.8s ease-in, visibility 0s 3.8s; /* visibility delay to match opacity */
}

.tooltip-container .tooltip-text a {
  text-decoration: underline;
  padding-left: 5px;
  color: white;
  cursor: pointer;
}

.tooltip-container:hover .tooltip-text { /* :hover */
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s ease, visibility 0s; /* visibility becomes instant */
  }
