/* Showcase container */
.showcase {
  max-height: 100%;
  margin-top: 160px;
  margin-bottom: 160px;
  width: 100%;
}

/* Video and text container */
.showcase-player-area {
  position: relative;
  display: flex;
  flex-direction: row; /* Arrange video and text-container side by side */
  justify-content: center;
  align-items: flex-start; /* Align top of items */
  flex-wrap: wrap; /* Allow wrapping of content if needed */
}

/* Text container */
.showcase-textarea {
  display: flex;
  padding: 35px 20px;
  flex-direction: column; /* Text and cover aligned in column inside this */
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left; 
  flex-grow: 1; /* Allow it to expand */
  max-width: 600px; /* Limit to a maximum of 600px */
  min-width: 300px; /* Ensure it doesn't shrink below 300px */
}

.showcase-description h2 {
  margin-bottom: 8px;
}

.showcase-caption {
  padding-top: 20px;
  line-height: 2;
}

@media (max-width: 1450px) {
  .showcase-textarea {
    margin-right: 0; /* Remove right margin when stacking vertically */
    padding: 0px;
    max-width: 790px;
  }
}

/* Responsive behavior for smaller screens */
@media (max-width: 1023px) {  
  .showcase-player-area {
    flex-direction: column; /* Stack the video and text-container vertically */
    align-items: center; 
  }

  .showcase-textarea {
    margin-right: 0;
    padding: 20px;
    line-height: 1.5;
    max-width: 400px;
  }
}
