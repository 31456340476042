/* Video container */
.showcase-video-container {
  position: relative;
  display: inline-block;
  flex-grow: 0; /* Prevent growing */
  padding: 40px 1%;
  max-width: 800px; /* Ensure video does not exceed this width */
  margin-right: 0; /* Remove unnecessary spacing */
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(35, 74, 99, 0.8);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10; /* Ensure it's above the video */
}

.play-button {
  color: white;
  padding-top: 6px;
  padding-left: 8px;
  font-size: 48px;
  line-height: 0;
}

/* Video and iframe styles */
#video-player {
  max-height: 480px;
  width: 700px;
  max-width: 800px;
  display: block;
}

/* Responsive behavior for smaller screens */
@media (max-width: 1023px) {
  .showcase-video-container {
    margin-right: 0; 
    padding: 20px 0px;
    max-width: 400px;
  }

  #video-player {
    max-height: 480px;
    min-width: auto;
    max-width: 90%;
    margin: auto;
  }
}

video {
  width: 100%;
  height: auto;
  max-width: 100vw; /* Ensure the video doesn't exceed the viewport width */
  max-height: 100vh; /* Ensure the video doesn't exceed the viewport height */
}