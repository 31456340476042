/* Video container */
.youtube-video-container {
  flex-grow: 0; /* Prevent growing */
  padding: 40px 1%;
  max-width: 800px; /* Ensure video does not exceed this width */
  margin-right: 0; /* Remove unnecessary spacing */
}

#youtube-iframe {
  max-height: 480px;
  height: 480px;
  width: 700px;
  display: block;
}

@media (max-width: 1023px) {
  #youtube-iframe {
    max-height: 480px;
    width: 100%;
  }

  .youtube-video-container {
    margin-right: 0; /* Remove right margin when stacking vertically */
    padding: 20px 0px;
    width: 100%;
  }
}
@media (max-width: 500px) {
  #youtube-iframe {
    max-height: 480px;
    height: 340px;
    width: 100%;
  }
}
