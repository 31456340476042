.navbar {
  text-decoration: none;
  background-color: #1D2832;
  display: flex;
  justify-content: right;
  height: 80px;
  margin: 0;
  position: fixed; /* Make the navbar fixed at the top */
  top: 0; /* Position it at the top */
  right: 0;
  width: 100%;
  z-index: 110; /* Ensure it stays above other content */
}

.icon {
  position: fixed;
  top: 25px;
  left: 50px;
  width: 30px;
  z-index: 200;
  opacity: 0;
  transition: opacity 0.1s ease;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 1));
}

.navbar .icon {
  position: absolute;
  top: 10px;
  left: 25px;
  width: 60px;
  z-index: 200;
}

.navbar li {
  list-style-type: none;
  padding: 0;
  padding: 10px 0;
  display: flex;
  width: auto;
}

.navbar li a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin-left: 10px;
  width: 122px;
  height: 35px;
  color: white;
  background: #1D2832;
  font-style: normal;
  font-weight: 400;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.5s ease-out;
}

.navbar li a:hover {
  background-color: #3A4F63;
  transition: background-color 0.5s ease-in;
}

.navbar li a:active {
  background-color: #3A4F63;
  font-style: normal;
  font-weight: 700;
}

.navbar li a.selected {
  background-color: #1D2832;
  transition: background-color 0.5s ease-out;
  font-style: normal;
  font-weight: 700;
}

.dropdown-content {
  display: flex; /* list in a row */
  list-style-type: none; /* Remove the bullet points */
  justify-content: right;
  height: 80px;
  margin: 0;
  padding-right: 20px;
  position: fixed; /* Make the navbar fixed at the top */
  top: 0px; /* Position it at the top */
  right: 0;
  width: 100%;
  background-color: rgba(29, 40, 50, 0.3);
  z-index: 100;
  transition: top .3s ease;
}

.dropdown-content.down {
  top: 80px; /* Position it at the top */
  transition: top 0.5s ease-out;
}

.dropdown-content li {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}

.dropdown-content li a {
  color: white;
  text-decoration: none;
  font-weight: 400;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 6px;
  width: 122px;
  height: 35px;
  transition: background-color 0.5s ease-out;
}

.dropdown-content li a:hover {
  background-color: rgba(58, 79, 99, 0.3);
  transition: background-color 0.5s ease-in;
}

.dropdown-content li a.selected {
  background-color: #1D2832;
  transition: background-color 0.5s ease-out;
  font-style: normal;
  font-weight: 700;
}


@media screen and (max-width: 500px) {
  .navbar li a {
    padding: 10px 10px;
    margin-left: 0px;
    margin-right: 10px;
    width: auto;
  }
  
    .dropdown-content {
    padding-right: 0px; /* Reduce padding to 0 when window width is less than 500px */
  }

  .dropdown-content li a {
    padding: 10px 5px;
    padding: 10px 10px;
    margin-left: 0px;
    margin-right: 10px;
    width: auto;
  }
}
