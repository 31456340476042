#bandcamp-iframe {
  flex: 1;
  padding: 50px 1% 0px 1%;
  min-height: 480px;
  max-width: 700px;
  min-width: 400px;
  height: auto; /* Maintain aspect ratio */
  width: 700px;
  border: 0;
}

/* Showcase cover */
.album-cover {
  max-width: 400px;
  height: 400px;
  padding-top: 50px;
  overflow: hidden;
  position: relative;
  margin-left: 10px; /* Adjust the spacing if needed */
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.album-cover img {
  max-height: 400px;
  width: auto;
  height: auto;
  object-fit: contain; /* Ensures the entire image fits inside, keeping aspect ratio */
}


@media screen and (max-width: 1799px) {
  #bandcamp-iframe {
    height: auto; 
    width: auto; 
    min-width: 400px;
  }

}

/* Responsive behavior for smaller screens */
@media (max-width: 1024px) {
  #bandcamp-iframe {
    height: auto; 
    width: auto; 
    min-width: 100%;
    padding-top: 10px;
  }

  .album-cover {
    height: auto;
    padding-top: 10px;
  }

  .album-cover img {
    padding-top: 0px;
    margin-left: 0; 
    width: 98%;
  }
}
