body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: #234A63;
  color: white;
  font-family: 'Roboto Serif', serif;
  font-size: 13px;
  line-height: 15.22px;
  overflow: auto;
}

html::-webkit-scrollbar {
  width: 0;  /* For vertical scroll */
  height: 0; /* For horizontal scroll */
}

html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;      /* Firefox */
}
