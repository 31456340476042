
.thumbnail-slider-wrapper {
  position: fixed;
  bottom: -130px; /* hide slider until there is something in to*/
  left: 0;
  width: 100%;
  height: 150px;
  background-color: #315a81;
  transition: bottom 500ms ease-out; 
  z-index: 110;
}

.thumbnail-slider {
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  opacity: 0;
  transition: opacity 1s ease-in-out; 
}
.thumbnail-slider::-webkit-scrollbar {
  display: none;
}

.thumbnail-slider img:hover {
  border: 8px solid rgba(255, 255, 255, 0.3);
}

.thumbnail-slider img.selected {
  border: 8px solid #1D2832;
}

.thumbnail-item {
  margin: auto 5px;
  position: relative;
  display: inline-block; /* Ensures the content wraps around the image and title */
}


.thumbnail-image {
  display: block;
  cursor: pointer;
  margin: 10px;
  height: 100px;
  width: 100px;
  border-radius: 15px;
  border: 8px solid rgba(0, 0, 0, 0);
  transition: transform 0.2s, border 0.2s;
  object-fit: cover;    /* Maintain aspect ratio, cropping the image if necessary */
  object-position: top center;  /* Keep the top and left sides visible */

}

.thumb-title {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 8px 0;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: small;
  z-index: 1;
  width: calc(100% - 36px);
  pointer-events: none;
}

.thumb-title:before,
.thumb-title:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 30px; 
  background: rgba(0, 0, 0, 0.6); 
  filter: blur(15px); 
}

.thumb-title:before {
  top: -15px; 
}

.thumb-title:after {
  bottom: -15px; 
}
